import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DisneyLogo from './DisneyLogo'
import VideoModal from "../shared/VideoModal";
import { media } from "utils/Media"


const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-bottom: solid 1px #000;
  padding: .5rem 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1920px;
    width: 90%;
    margin: 0 auto;
    padding: 1rem 0;
    @media (min-width: 992px) {
      padding: 0;
    }
    &--wrapper {
      @media (min-width: 992px) {
        display: flex;
        align-items: center;
        gap: 30px;
        ul {
          display: flex;
          li {
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
      .logo {
        margin-left: 60px;
        svg {
          width: auto;
          height: 40px;
        }
        @media (min-width: 992px) {
          margin: 0;
          svg {
            height: 41px;
          }
        }
      }
      .navigation {
        display: none;
        @media (min-width: 992px) {
          display: block;
          width: 100%;
          .nav {
            padding: 0;
            margin: 0;
            > li {
              padding: 1rem 1.5rem;
            }
          }
        }
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      button {
        display: inline-block;
        cursor: pointer;
        border: none;
        padding: 0.35rem 1rem;
        background: #3B1293;
        /* background: linear-gradient(135deg, #9A8AFF 0%, #3B1293 48.96%, #000355 100%); */
        cursor: pointer;
        border: none;
        &:hover,
        &:active {
          /* background: linear-gradient(135deg, #000355 0%, #3B1293 48.96%, #9A8AFF 100%); */
          background: #000355;
          
        }
        .desktop-text {
        display: none;
      }
      span {
        color: #fff;
        font-weight: 700;
        letter-spacing: 0.25px;
      }
      @media (min-width: 1280px) {
        width: 180px;
        text-align: center;
        padding: 0.5rem 0;
        .mob-text {
          display: none;
        }
        .desktop-text {
          display: block;
        }
      }
      }
      .trailer {
        display: none;
        @media ${media.xl} {
          background: linear-gradient(135deg, #000355 0%, #3400A3 100%);
          display: inline-block;
          position: relative;
          padding: 3px;
          div {
            padding: 0.35rem 1rem;
            display: block;
            width: 100%;
            height: 100%;
            background: #fff; 
            span {
              z-index: 10;
              position: relative;
              color: #000;
            }
          }
        }
      }
  
    }
  }
`
export default function NewHeader() {
  const [open, setOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)

  useEffect(() => {
    if (open) {
      document.documentElement.classList.add('nav-active')
    } else {
      document.documentElement.classList.remove('nav-active')
    }
  })
  return (
    <>
      <StyledHeader id="header" open={open}>
        <div className="header">
          {/* <Hamburger open={open} setOpen={setOpen} /> */}
          <div className="header--wrapper">
            <div className="logo">
              <a href="/">
                <DisneyLogo />
              </a>
            </div>
            <div className="navigation">
              {/* <Navigation
                open={open}
                setOpen={setOpen}
                videoOpen={videoOpen}
                setVideoOpen={setVideoOpen}
              /> */}
            </div>
          </div>
          {/* <div className="buttons">
              <button type="button" className='trailer' onClick={() => setVideoOpen(true)}>
                <div>
                  <span>WATCH TRAILER</span>
                </div>
              </button>
              <a href="https://booking.lwtheatres.co.uk/event/2c62fa9a-9025-ea11-a9ca-064c84cbe4ea/">
              <button type="button">
                <span className="mob-text">TICKETS</span>
                <span className="desktop-text">BOOK TICKETS</span>
              </button>
            </a>
          </div> */}
        </div>
      </StyledHeader>
      {/* <MobileMenu
        open={open}
        setOpen={setOpen}
        videoOpen={videoOpen}
        setVideoOpen={setVideoOpen}
      /> */}
      <VideoModal toggleModal={setVideoOpen} src='https://secure.disney.com/embed/605f07c75829debd6b4f2a72?domain=www.disney.co.uk'
      modalOpen={videoOpen} setVideoOpen={setVideoOpen}/> 
    </>
  )
}



// onClick={(e) => this.toggleVideoModal(e, "")}